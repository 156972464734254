import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section className="row chapter breathe" style={{ height: '100vh' }}>
      <div className="container container-960">
        <div className="row">
          <div className="col-xs-12">
            <h1>NOT FOUND</h1>
            <p>
              The page you were looking for doesn't exist. Maybe it is down for
              maintenance.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    allWordpressWpApiMenusMenusItems(filter: { name: { eq: "test" } }) {
      edges {
        node {
          count
          items {
            order
            title
            object_slug
          }
        }
      }
    }
  }
`
